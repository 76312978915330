<template>
  <hooper :settings="hooperSettings" :class="{'-white' : true, '-small': items.length <= 1}" ref="carousel" @slide="updateProgress" @updated="updateSlider" tabindex="-1">
    <slide v-for="(product, i) in items" :key="i" :index="i">
      <ModalProductItem :product="product"></ModalProductItem>
    </slide>
    <div class="hooper-navigation container" slot="hooper-addons" v-if="items.length > 1">
      <button type="button" class="hooper-prev" :class="{'is-disabled': !currentSlide}" :aria-label="$t('Previous slide')" @click="() => $refs.carousel.slidePrev()"></button>
      <button type="button" class="hooper-next" :class="{'is-disabled': currentSlide >= items.length - 1}" :aria-label="$t('Next slide')" @click="() => $refs.carousel.slideNext()"></button>
    </div>
    <hooper-progress v-if="items.length > 1" slot="hooper-addons"></hooper-progress>
  </hooper>
</template>

<script>
import {
  Hooper,
  Slide,
  Progress as HooperProgress
} from 'hooper'
// import 'hooper/dist/hooper.css'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { ReadyHook } from 'vue-asyncready'
import ModalProductItem from './ModalProductItem'

export default {
  props: ['items', 'pagePosition', 'siblings-components'],
  name: 'defy_extreme_modal_product_slider',
  mixins: [ReadyHook],
  components: {
    Hooper,
    Slide,
    HooperProgress,
    ModalProductItem
  },
  computed: {
    localizedUrl() {
      return currentStoreView().url || ''
    }
  },
  data() {
    return {
      currentSlide: 0,
      itemsToShow: 0,
      hooperSettings: {
        initialSlide: 0,
        itemsToShow: 1,
        transition: 400,
        centerMode: true,
        autoPlay: false,
        mouseDrag: (this.items.length > 1),
        shortDrag: true,
        touchDrag: (this.items.length > 1),
        hoverPause: false,
        keysControl: true,
        wheelControl: false,
        trimWhiteSpace: true,
        infiniteScroll: false,
      }
    }
  },
  methods: {
    updateSlider (payload) {
      this.itemsToShow = payload.settings.itemsToShow
    },
    updateProgress (payload) {
      this.currentSlide = payload.currentSlide
    }
  },
  mounted () {
   // this.callGTM()
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/core';

.hooper {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  outline: none;
  overflow: visible;

  &.-small {
    /deep/ .hooper-track {
      justify-content: center;
      transform: none !important
    }

    /deep/ .hooper-liveregion.hooper-sr-only {
      display: none;
    }
  }

  /deep/ .hooper-list {
    overflow: visible;
  }

  &-slide {
    position: relative;
    outline: none;
    height: auto;
    padding: 3rem 3rem;

    //min-width: 250px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include bp(max md) {
      padding: 5rem 5rem;
      width: 80%;
    }

    /deep/ .obs {
      height: 100%;
    }
  }
}

/deep/ .hooper-navigation {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);

  @include bp(max md) {
    display: none;
  }

  .hooper-prev {
    left: -3rem;
    outline: none;
    &:before {
      content: "\e903";
    }
    &:hover {
      &:before {
        @include pillPrevHover();
      }
    }
  }
  .hooper-next {
    right: -3rem;
    outline: none;
    &:before {
      content: "\e925";
    }
    &:hover {
      &:before {
        @include pillNextHover();
      }
    }
  }

  button {
    opacity: 1;
    padding: 1.3rem;
    border-radius: 100%;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.3);
    background: #f8f8f8;

    svg {
      display: none;
    }

    &:before {
      display: block;
      font-family: 'zenith', sans-serif !important;
      speak: none;
      font-size: em(20);
      line-height: 2rem;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;

      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    &.is-disabled {
      opacity: 0 !important;
      pointer-events: none;
    }

    &:hover,
    &:active,
    &:focus {
      opacity: 1;
    }
  }
}

/deep/ .hooper-progress {
  position: absolute;
  top: auto;
  bottom: 3rem;
  background: #cccccc;
  height: .2rem;
  margin-top: 3rem;
  margin-left: 1rem;
  margin-right: 1rem;

  @include bp(md) {
    display: none;
  }

  &-inner {
    background: #000;
  }
}
</style>

