<template>
  <transition name="fade">
    <div id="modal-product" class="modal"  @click.self="$emit('close')" v-if="isOpen">
      <div class="content" v-bsl="isOpen">
        <button class="close" type="button" ref="close" @click="$emit('close')" :aria-label="$t('Close')"><i class="icon-close"></i></button>

        <div class="wrapper">
          <div class="col-left" v-if="!isMobile">
            <img ref="picture" :src="imageLowDefinition" :alt="data.image.alt || ' '" v-if="data.image">
            <template v-else-if="data.video && data.video.url" >
              <video ref="video" autoplay playsinline loop muted>
                <source :src="data.video.url" type="video/mp4">
              </video>
              <button type="button" class="btn-autoPlay" :class="{'-paused': pauseVideo}" :aria-label="pauseVideo ? 'Play' : 'Pause'" @click="toggleVideo" />
            </template>
          </div>
          <div class="col-right" v-if="data.products">
            <div class="container">
              <div class="row justify-content-center">
                <ModalProductSlider class="col-12 col-md-8 col-xl-8" :items="data.products"></ModalProductSlider>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { ReadyHook } from 'vue-asyncready'
import ModalProductSlider from './ModalProductSlider'
import ImageManager from 'vue-storefront-prismic-ssr/utilities/ImageManager'

export default {
  props: ['isOpen', 'data'],
  name: 'defy_extreme_modal_product',
  mixins: [ReadyHook],
  components: {
    ModalProductSlider
  },
  data () {
    return {
      pauseVideo: false
    }
  },
  watch: {
    isOpen (newVal) {
      if (newVal) {
        this.$nextTick(() => {
          if (!this.isMobile && this.data.image && this.data.image.url) {
            this.$refs.picture.srcset = ImageManager.getSrcSet(this.data.image.url, this.data.image.width, 200, 100, this.data.image.height / this.data.image.width)
          }
          this.$refs.close.focus()
        })
        if (typeof document !== 'undefined') {
          document.body.style.overflowY = 'hidden'
        }
      } else {
        if (typeof document !== 'undefined') {
          document.body.style.overflowY = 'auto'
        }
      }
    }
  },
  computed: {
    imageLowDefinition() {
      return ImageManager.getLowDefinition(this.data.image.url, Math.round(this.data.image.width / 4), this.data.image.height / this.data.image.width)
    },
    isMobile() {
      return this.$store.state['zenith-ui'].menu.mobile
    }
  },
  methods: {
    keyup(e) {
      if (e.keyCode === 27) {
        this.$emit('close')
      }
    },
    trapFocus(e) {
      if (this.isOpen && !(this.$el === e.target || this.$el.contains(e.target)) && (!e.target.classList || !e.target.classList.contains('modal'))) {
        this.$refs.close.focus()
      }
    },
    toggleVideo() {
      if (this.pauseVideo) {
        this.$refs.video.play()
      } else {
        this.$refs.video.pause()
      }
      this.pauseVideo = !this.pauseVideo
    }
  },
  mounted() {
    document.addEventListener('keyup', this.keyup)
    document.addEventListener('focus', this.trapFocus, true)
  },
  destroyed() {
    document.removeEventListener('keyup', this.keyup)
    document.removeEventListener('focus', this.trapFocus, true)
  }
}
</script>

<style lang="scss" scoped>
@import "~theme/css/core";

.fade-enter-active {

}
.fade-leave-active {

}

.fade-enter {
  opacity: 0;

  .col-left {
    opacity: 0;
    img, video {
      transform: scale(0.9, 0.9);
    }
  }
  .col-right {
    transform: translateX(100%);
  }
}
.fade-enter-to {
  opacity: 1;

  .col-left {
    opacity: 1;
    img, video {
      transform: scale(1, 1);
    }
  }
  .col-right {
    transform: translateX(0%);
  }
}
.fade-leaver {
  opacity: 1;
}
.fade-leave-to {
  opacity: 0;
}

.modal {
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  .content {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: var(--zenith-component-text);
    overflow: hidden;
    background: #ececec;

    @include bp(max md) {
      background: none;
      width: 100%;
      max-height: 100%;
    }

    .close {
      position: absolute;
      z-index: 3;
      top: 2rem;
      right: 2rem;
      padding: .4rem;
      border: none;
      background: none;
      color: var(--zenith-black);

      @include bp(max md) {
        top: 1rem;
        right: 1rem;
      }

      i {
        display: block;
        font-size: em(30, 10);
      }
    }

    .wrapper {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 100%;

      .col-left {
        position: relative;
        flex: 1 0 60%;
        padding: 6.6rem 10rem;
        overflow: hidden;
        background: #ececec;
        transition: opacity 0.6s $Power3EaseOut;
        text-align: center;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: transform 0.6s $Power3EaseOut;
        }
        video {
          max-width: 100%;
          height: 100%;
          object-fit: cover;
          transition: transform 0.6s $Power3EaseOut;
        }

        @include bp(max lg) {
          display: none;
        }
      }

      .col-right {
        flex: 1 0 40%;
        background-color: var(--zenith-white);
        overflow: hidden;

        transition: transform 0.6s $Power3EaseOut;

        @include bp(max lg) {
          flex-basis: 100%;
        }

        .container {
          height: 100%;

          .row {
            height: 100%;
          }
        }

      }

      .btn-autoPlay {
        outline-color: var(--zenith-black);

        &:hover, &:focus {
          background: var(--zenith-black);

          &:before {
            border-left-color: var(--zenith-white);
          }

          &:after {
            border-left-color: var(--zenith-white);
            border-right-color: var(--zenith-white);
          }
        }

        &:before {
          border-left: 6px solid var(--zenith-black);
        }
        &:after {
          border-right: 2px solid var(--zenith-black);
          border-left: 2px solid var(--zenith-black);
        }
      }
    }

  }
}
</style>
